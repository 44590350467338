@import url('https://fonts.googleapis.com/css2?family=Anton&family=Onest:wght@100..900&family=Oxanium:wght@200..800&display=swap');

.header {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFA53E;
    height: 100px;
    width: 100%;
    padding: 0 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.logo {
    height: 100px;
    width: 100px;
    transition: transform 0.3s ease, filter 0.3s ease;
    user-select: none;
}

.title {
    font-size: 38px;
    color: white;
    font-family: 'Oxanium', sans-serif;
    font-weight: 700;
    position: absolute;
    left: calc(80px + 40px);
    cursor: default;
    transition: transform 0.3s ease, filter 0.3s ease;
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
}

.buttons {
    display: flex;
    margin-left: 45vw;
    margin-right: 355px;
    user-select: none;
}

.button {
    margin-left: 69px;
    padding: 10px 20px;
    font-size: 28px;
    font-weight: 600;
    font-family: 'Onest', sans-serif;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: transform 0.3s ease, filter 0.3s ease;
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
}

.button:hover {
    transform: scale(0.95);
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5)) blur(0.5px);
}

.button:first-child {
    margin-left: 0;
}

.Menu {
    display: none;
    position: absolute;
    transition: transform 0.3s ease, filter 0.3s ease;
    cursor: pointer;
}

.Menu:hover{
    transform: scale(1.02);
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5)) blur(0.5px);
}

@media (max-width: 1200px){
    .logo{
        height: 80px;
        width: 80px;
    }
    .title{
        font-size: 28px;
        left: calc(8%);
    }
    .buttons{
        margin-left: 250px;
    }
    .button{
        font-size: 24px;
    }
    .header{
        height: 90px;
    }
}

@media (max-width: 992px) {
    .logo{
        height: 75px;
        width: 75px;
    }
    .title {
        font-size: 26px;
        left: calc(9%);
    }
    .buttons{
        display: none;
    }
    .Menu{
        display: flex;
        left: calc(92%);
        height: 47px;
        width: 47px;
    }
    .header{
        height: 80px;
    }
}

@media (max-width: 768px) {
    .logo{
        height: 70px;
        width: 70px;
    }
    .title{
        font-size: 24px;
        left: calc(11%);
    }
    .buttons{
        display: none;
    }
    .Menu{
        display: flex;
        left: calc(90%);
        height: 47px;
        width: 47px;
    }
    .header{
        height: 75px;
    }
}

@media (max-width:550px){
    .logo{
        height: 68px;
        width: 68px;
    }
    .title{
        font-size: 22px;
        left: calc(15%);
    }
    .buttons{
        display: none;
    }
    .Menu{
        display: flex;
        left: calc(88%);
        height: 43px;
        width: 43px;
    }
    .header{
        height: 73px;
    }
}

@media (max-width: 480px){
    .logo{
        height: 65px;
        width: 65px;
    }
    .title{
        font-size: 22px;
        left: calc(17%);
    }
    .buttons{
        display: none;
    }
    .Menu{
        display: flex;
        left: calc(86%);
        height: 43px;
        width: 43px;
    }
    .header{
        height: 70px;
    }
}

@media (max-width: 415px){
    .logo{
        height: 63px;
        width: 63px;
    }
    .title{
        font-size: 21px;
        left: calc(19%);
    }
    .buttons{
        display: none;
    }
    .Menu{
        display: flex;
        left: calc(84%);
        height: 43px;
        width: 43px;
    }
    .header{
        height: 68px;
    }
}

@media (max-width: 320px){
    .logo{
        height: 60px;
        width: 60px;
    }
    .title{
        font-size: 20px;
        left: calc(23%);
    }
    .buttons{
        display: none;
    }
    .Menu{
        display: flex;
        left: calc(81%);
        height: 40px;
        width: 40px;
    }
    .header{
        height: 65px;
    }
}