body {
    margin: 0;
    height: 100vh;
    overflow-x: hidden;
}

.background {
    background: linear-gradient(to bottom, #EEAA48, #E58A1F);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.content {
    width: 2000px;
    height: 1463px;
    position: relative;
    left: 50px;
}

.center-logo {
    position: absolute;
    top: 200px;
    left: 330px;
    align-items: center;
    max-width: 600px;
    height: auto;
    transition: background-color 0.3s, transform 0.3s ease, filter 0.3s ease;
    user-select: none;
}

.main-title {
    position: absolute;
    top: 335px;
    left: 830px;
    font-size: 110px;
    color: white;
    cursor: default;
    font-family: 'Anton', sans-serif;
    font-weight: 400;
    margin: 0;
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.2));
    transition: background-color 0.3s, transform 0.3s ease, filter 0.3s ease;
}

.sub-title {
    position: absolute;
    font-family: 'Onest', sans-serif;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 500;
    top: 490px;
    left: 830px;
    font-size: 24px;
    color: white;
    margin: 0;
    transition: background-color 0.3s, transform 0.3s ease, filter 0.3s ease;
    white-space: nowrap;
}

.action-button {
    position: absolute;
    top: 540px;
    left: 825px;
    display: flex;
    align-items: center;
    width: 380px;
    height: 65px;
    padding: 10px 20px;
    font-size: 22px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    color: #D9D9D9;
    background-color: #313338;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s ease, filter 0.3s ease;
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.button-image {
    position: relative;
    width: 80px;
    height: 58px;
    top: 6px;
    left: -10px;
    user-select: none;
}

.action-button:hover {
    background-color: #363636;
    transform: scale(0.97);
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5)) blur(0.5px);
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #e69232;
    transition: background-color 0.3s, transform 0.3s ease, filter 0.3s ease;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: #EEAA48;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #eb9a3e;
}

.backgroundshadow {
    width: 8000px;
    height: 4945px;
    position: absolute;
    top: 1000px;
    z-index: 9;
    user-select: none;
}

.backgroundblack {
    position: absolute;
    width: 8000px;
    height: 4869px;
    top: 1020px;
    z-index: 10;
    user-select: none;
}


.star {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 1200px;
    right: calc(15%);
    z-index: 11;
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.star2 {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 1150px;
    right: calc(80%);
    z-index: 11;
    transform: rotate(120deg);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.star3 {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 2000px;
    right: calc(5%);
    z-index: 11;
    transform: rotate(230deg);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.star4 {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 2200px;
    right: calc(45%);
    z-index: 11;
    transform: rotate(140deg);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.star5 {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 2050px;
    right: calc(85%);
    z-index: 11;
    transform: rotate(180deg);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.star6 {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 3150px;
    right: calc(80%);
    z-index: 11;
    transform: rotate(170deg);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.star7 {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 3350px;
    right: calc(45%);
    z-index: 11;
    transform: rotate(180deg);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.star8 {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 3300px;
    right: calc(10%);
    z-index: 11;
    transform: rotate(180deg);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.star9 {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 4300px;
    right: calc(10%);
    z-index: 11;
    transform: rotate(135deg);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.star10 {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 4500px;
    right: calc(40%);
    z-index: 11;
    transform: rotate(180deg);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.star11 {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 4350px;
    right: calc(80%);
    z-index: 11;
    transform: rotate(180deg);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.star12 {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 5250px;
    right: calc(70%);
    z-index: 11;
    transform: rotate(70deg);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.star13 {
    position: absolute;
    height: 350px;
    width: 350px;
    top: 5350px;
    right: calc(15%);
    z-index: 11;
    transform: rotate(35deg);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    user-select: none;
}

.screen1 {
    position: absolute;
    width: 500px;
    height: 310px;
    top: 1560px;
    right: 150px;
    z-index: 11;
    transition: background-color 0.3s, transform 0.3s ease, filter 0.3s ease;
    user-select: none;
}

.screen1:hover {
    transform: scale(1.05);
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
}

.SQUADS {
    color: white;
    font-size: 90px;
    font-family: 'Onest', sans-serif;
    font-weight: 900;
    word-wrap: break-word;
    position: absolute;
    top: 1550px;
    left: calc(5%);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    z-index: 12;
}

.SQUADStext {
    color: #D9D9D9;
    font-size: 24px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    top: 1650px;
    z-index: 12;
    margin-right: calc(56%);
    width: 550px;
}

.screen2 {
    position: absolute;
    z-index: 11;
    width: 400px;
    height: 440px;
    top: 2680px;
    right: calc(75%);
    transform: scale(1.05);
    transition: background-color 0.3s, transform 0.3s ease, filter 0.3s ease;
    user-select: none;
}

.screen2:hover {
    transform: scale(1.10);
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
}

.TEGS {
    color: white;
    font-size: 90px;
    font-family: 'Onest', sans-serif;
    font-weight: 900;
    word-wrap: break-word;
    position: absolute;
    top: 2650px;
    right: calc(12%);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    z-index: 12;
}

.TEGStext {
    color: #D9D9D9;
    font-size: 24px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    top: 2750px;
    z-index: 12;
    margin-left: calc(55%);
    width: 550px;
}

.ROLES {
    color: white;
    font-size: 90px;
    font-family: 'Onest', sans-serif;
    font-weight: 900;
    word-wrap: break-word;
    position: absolute;
    top: 3775px;
    left: calc(5%);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    z-index: 12;
}

.ROLEStext {
    color: #D9D9D9;
    font-size: 24px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    top: 3875px;
    z-index: 12;
    margin-right: calc(55%);
    width: 575px;
}

.screen3 {
    position: absolute;
    z-index: 11;
    width: 820px;
    height: 275px;
    top: 3850px;
    right: calc(5%);
    transform: rotate(2deg);
    transition: background-color 0.3s, transform 0.3s ease, filter 0.3s ease;
    user-select: none;
}

.screen3:hover {
    transform: scale(1.05);
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
}

.MANAGE {
    color: white;
    font-size: 80px;
    font-family: 'Onest', sans-serif;
    font-weight: 900;
    word-wrap: break-word;
    position: absolute;
    top: 4775px;
    right: calc(12%);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    z-index: 12;
}

.MANAGEtext {
    color: #D9D9D9;
    font-size: 24px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    top: 4874px;
    z-index: 12;
    margin-left: calc(55%);
    width: 575px;
}

.screen4 {
    position: absolute;
    z-index: 11;
    width: 475px;
    height: 328px;
    top: 4815px;
    right: calc(69%);
    transition: background-color 0.3s, transform 0.3s ease, filter 0.3s ease;
    user-select: none;
}

.screen4:hover {
    transform: scale(1.05);
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
}

.background2 {
    background: linear-gradient(to bottom, #EEAA48, #E58A1F);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 1550px;
    top: 5500px;
    width: 100%;
    user-select: none;
}

.StatsText {
    color: white;
    font-size: 100px;
    font-family: 'Onest', sans-serif;
    font-weight: 900;
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    position: absolute;
    top: 500px;
}

.squadsnumber {
    width: 300px;
    height: 300px;
    position: absolute;
    bottom: 385px;
    right: calc(76%);
    align-items: center;
    user-select: none;
}

.usersnumber {
    width: 525px;
    height: 290px;
    position: absolute;
    bottom: 375px;
    user-select: none;
}

.serversnumber {
    width: 275px;
    height: 275px;
    position: absolute;
    bottom: 395px;
    right: calc(11%);
    user-select: none;
}

.squadstextn {
    color: white;
    font-size: 50px;
    font-family: 'Onest', sans-serif;
    font-weight: 600;
    position: absolute;
    top: 1120px;
    right: calc(78%);
}

.userstextn {
    color: white;
    font-size: 50px;
    font-family: 'Onest', sans-serif;
    font-weight: 600;
    position: absolute;
    top: 1120px;
    left: calc(40%);
}

.serverstextn {
    color: white;
    font-size: 50px;
    font-family: 'Onest', sans-serif;
    font-weight: 600;
    position: absolute;
    top: 1120px;
    right: calc(12%);
}

.squadstextnum {
    color: white;
    font-size: 40px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    top: 1190px;
    right: calc(81% + calc(2%));
    align-items: center;
}

.serverstextnum {
    color: white;
    font-size: 40px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    top: 1200px;
    left: calc(81%);
}

.userstextnum {
    color: white;
    font-size: 40px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    top: 1200px;
}

@media (max-width: 1200px){
    .center-logo{
        height: 580px;
        width: 580px;
        left: 25px;
    }
    .main-title{
        font-size: 100px;
        left: 525px;
    }
    .sub-title{
        font-size: 22px;
        left: 525px;
    }
    .action-button{
        left: 510px;
    }
    .star{
        right: calc(10%);
    }
    .star2{
        right: calc(70%);
    }
    .star3{
        right: calc(2%);
    }
    .star4{
        right: calc(40%);
    }
    .star5{
        right: calc(75%);
        bottom: calc(20%);
    }
    .screen1{
        width: 480px;
        height: 300px;
        right: 30px;
        margin-bottom: 50px;
    }
    .SQUADS{
        white-space: nowrap;
        left: calc(-3%);
    }
    .SQUADStext{
        margin-right: calc(51%);
    }
    .screen2{
        left: calc(3%);
        bottom: calc(5%);   
    }
    .TEGS{
        left: calc(75%);
        white-space: nowrap;
    }
    .TEGStext{
        margin-left: calc(55%);
    }
    .screen2{
        margin-top: calc(-7%);
    }
    .star6{
        left: calc(1%);
        bottom: calc(20%);
    }
    .star7{
        left: calc(30%);
        bottom: calc(20%);
    }
    .star8{
        left: calc(70%);
        bottom: calc(20%);
    }
    .ROLES{
        left: calc(-3%);
        white-space: nowrap;
    }
    .ROLEStext{
        margin-right: calc(49%);
    }
    .screen3{
        width: 600px;
        height: 225px;
        left: calc(49%);
        top: calc(468%);
    }
    .star11{
        right: calc(70%);
        bottom: calc(20%);
    }
    .MANAGE{
        white-space: nowrap;
        left: calc(50%);
    }
    .MANAGEtext{
        margin-left: calc(55%);
    }
    .screen4{
        left: calc(5%);
        top: calc(575%);
    }
    .StatsText{
        font-size: 90px;
    }
    .squadsnumber{
        width: 250px;
        height: 250px;
    }
    .usersnumber{
        width: 485px;
        height: 267px;
    }
    .userstextn{
        left: calc(35%);
    }
    .serversnumber{
        width: 235px;
        height: 235px;
        right: calc(5%);
    }
    .serverstextn{
        right: calc(5%);
    }
    .squadstextnum{
        right: calc(85%);
    }
    .serverstextnum{
        left: calc(84%);
    }
}

@media (max-width: 992px) {
    .center-logo{
        height: 550px;
        width: 550px;
        left: 0px;
    }
    .main-title{
        font-size: 90px;
        left: 500px;
    }
    .sub-title{
        font-size: 21px;
        left: 500px;
    }
    .action-button{
        left: 485px;
        width: 360px;
        height: 60px;
    }
    .SQUADS{
        white-space: nowrap;
        left: calc(-4%);
        font-size: 80px;
    }
    .SQUADStext{
        width: 480px;
        margin-right: calc(49%);
    }
    .screen1 {
        width: 450px;
        height: 280px;
    }
    .TEGS{
        white-space: nowrap;
        left: calc(72%);
        font-size: 80px;
    }
    .TEGStext{
        margin-left: calc(48%);
    }
    .ROLES{
        white-space: nowrap;
        left: calc(-4%);
        font-size: 80px;
    }
    .ROLEStext{
        width: 500px;
        margin-right: calc(49%);
    }
    .screen3{
        width: 500px;
        height: 175px;
        left: calc(49%);
        top: calc(470%);
    }
    .MANAGE{
        white-space: nowrap;
        left: calc(49%);
        font-size: 65px;
    }
    .MANAGEtext{
        width: 500px;
        left: calc(-1%);
    }
    .screen4{
        width: 425px;
        height: 295px;
    }
    .serversnumber{
        width: 210px;
        height: 210px;
        right: calc(5%);
    }
    .squadsnumber{
        width: 230px;
        height: 230px;
    }
    .usersnumber{
        width: 450px;
        height: 248px;
        left: calc(25%);
    }
    .serverstextn{
        font-size: 47px;
    }
    .squadstextn{
        font-size: 47px;
    }
    .userstextn{
        left: calc(30%);
        font-size: 47px;
    }
    .userstextnum{
        left: calc(47%); 
    }
    .squadstextnum{
        left: calc(11%); 
    }
    .serverstextnum{
        left: calc(83%);  
    }
}

@media (max-width: 768px) {
    .center-logo{
        height: 500px;
        width: 500px;
        left: -80px;
    }
    .main-title{
        font-size: 85px;
        left: 375px;
    }
    .sub-title{
        font-size: 20px;
        left: 375px;

    }
    .action-button{
        left: 360px;
        width: 340px;
        height: 55px;
    }
    .SQUADS{
        white-space: nowrap;
        left: calc(19%);
        font-size: 75px;
        top: calc(101%);
    }
    .SQUADStext{
        width: 600px;
        margin-right: calc(3%);
        top: calc(183%);
        font-size: 23px;
        text-align: center;
    }
    .screen1{
        top: calc(210%);
        left: calc(19%);
        transform: rotate(-8deg);
    }
    .star5 {
        left: calc(-10%);
        bottom: calc(20%);
    }
    .star4 {
        left: calc(30%);
        bottom: calc(20%);
    }
    .TEGS{
        left: calc(30%);
        font-size: 75px;
        top: calc(165%);
    }
    .TEGStext{
        width: 600px;
        right:  calc(12%);
        top: calc(295%);
        font-size: 23px;
        text-align: center;
    }
    .screen2{
        top: calc(335%);
        left: calc(25%);
        width: 350px;
        height: 380px;
    }
    .ROLES{
        left: calc(28%);
        font-size: 75px;
        top: calc(250%);
    }
    .ROLEStext{
        width: 600px;
        margin-right: calc(3%);
        top: calc(445%);
        font-size: 23px;
        text-align: center;
    }
    .screen3{
        left: calc(19%);
        transform: rotate(-11deg);
        height: 200px;
    }
    .star9 {
        left: calc(60%);
        bottom: calc(20%);
    }
    .star10 {
        left: calc(20%);
        bottom: calc(20%);
    }
    .star11 {
        left: calc(-10%);
        bottom: calc(20%);
    }
    .screen4{
        left: calc(22%);
        top: calc(605%);
        transform: rotate(5deg);
    }
    .MANAGE{
        left: calc(10%);
        font-size: 75px;
        top: calc(325%);
    }
    .MANAGEtext{
        width: 600px;
        left:  calc(-43%);
        font-size: 23px;
        text-align: center;
    }
    .star12 {
        left: calc(-10%);
        bottom: calc(20%);
    }
    .star13 {
        left: calc(50%);
        bottom: calc(20%);
    }
    .serversnumber{
        width: 180px;
        height: 180px;
        right: calc(5%);
    }
    .squadsnumber{
        width: 200px;
        height: 200px;
    }
    .usersnumber{
        width: 370px;
        height: 204px;
        left: calc(23%);
    }
    .serverstextn{
        font-size: 40px;
    }
    .squadstextn{
        font-size: 40px;
    }
    .userstextn{
        font-size: 38px;
    }
    .squadstextnum{
        font-size: 32px;
    }
    .serverstextnum{
        font-size: 32px;
    }
    .userstextnum{
        font-size: 32px;
    }
    .serverstextnum{
        left: calc(82%);  
    }
}

@media (max-width: 550px) {
    .center-logo{
        height: 500px;
        width: 500px;
        left: -30px;
    }
    .main-title{
        font-size: 85px;
        left: 100px;
        margin-top: 300px;
    }
    .sub-title{
        font-size: 20px;
        left: 70px;
        margin-top: 290px;
    }
    .action-button{
        left: 70px;
        width: 340px;
        height: 55px;
        margin-top: 290px;
    }
    .SQUADS{
        white-space: nowrap;
        left: calc(13%);
        font-size: 65px;
        top: calc(101%);
    }
    .SQUADStext{
        width: 500px;
        margin-right: calc(3%);
        top: calc(185%);
        font-size: 20px;
        text-align: center;
    }
    .screen1{
        left: calc(10%);
    }
    .star2 {
        display: none;
    }
    .star4 {
        display: none;
    }
    .TEGS{
        left: calc(25%);
        font-size: 65px;
        top: calc(165%);
    }
    .TEGStext{
        width: 500px;
        right:  calc(5%);
        font-size: 20px;
    }
    .screen2{
        top: calc(325%);
        left: calc(21%);
        width: 325px;
        height: 365px;
    }
    .star8 {
        display: none;
    }
    .ROLES{
        left: calc(23%);
        font-size: 65px;
        top: calc(250%);
    }
    .screen3{
        left: calc(5%);
        transform: rotate(-11deg);
        height: 200px;
    }
    .ROLEStext{
        width: 500px;
        margin-right: calc(2%);
        top: calc(445%);
        font-size: 20px;
        text-align: center;
    }
    .star10 {
        display: none;
    }
    .MANAGE{
        left: calc(0%);
        font-size: 65px;
        top: calc(320%);
    }
    .MANAGEtext{
        width: 500px;
        left:  calc(-50%);
        top: calc(567%);
        font-size: 20px;
        text-align: center;
    }
    .screen4{
        left: calc(13%);
        top: calc(590%);
        transform: rotate(5deg);
    }
    .StatsText{
        font-size: 70px;
        margin-top: calc(-40%);
    }
    .usersnumber{
        left: calc(23%);
        top: calc(30%);
        width: 300px;
        height: 166px;
    }
    .squadsnumber{
        width: 200px;
        height: 200px;
        left: calc(32%);
        top: calc(50%);
    }
    .serversnumber{
        width: 175px;
        height: 175px;
        left: calc(34%);
        top: calc(73%);
    }
    .serverstextn{
        left: calc(33%);
        top: calc(83%);
    }
    .squadstextn{
        left: calc(35%);
        top: calc(60%);
    }
    .userstextn{
        left: calc(24%);
        top: calc(39%);
    }
    .userstextnum{
        left: calc(48%);
        top: calc(43%);
    }
    .squadstextnum{
        left: calc(48%);
        top: calc(64%);
    }
    .serverstextnum{
        left: calc(48%);
        top: calc(87%);
    }
    .background2{
        top: 5600px;
    }
}

@media (max-width: 480px){
    .center-logo{
        height: 500px;
        width: 500px;
        left: -50px;
    }
    .main-title{
        font-size: 85px;
        left: 80px;
        margin-top: 300px;
    }
    .sub-title{
        font-size: 20px;
        left: 50px;
        margin-top: 290px;
    }
    .action-button{
        left: 50px;
        width: 340px;
        height: 55px;
        margin-top: 290px;
    }
    .star {
        left: calc(-10%);
        bottom: calc(20%);
    }
    .star2 {
        left: calc(40%);
        bottom: calc(20%);
    }
    .SQUADS{
        white-space: nowrap;
        left: calc(10%);
        font-size: 62px;
        top: calc(101%);
    }
    .SQUADStext{
        width: 450px;
        margin-right: calc(3%);
        top: calc(185%);
        font-size: 19px;
        text-align: center;
    }
    .screen1{
        left: calc(3%);
    }
    .TEGS{
        left: calc(22%);
        font-size: 62px;
        top: calc(175%);
    }
    .TEGStext{
        width: 450px;
        right:  calc(4%);
        font-size: 19px;
        top: calc(313%);
    }
    .screen2{
        top: calc(340%);
        left: calc(18%);
        width: 300px;
        height: 330px;
    }
    .star8 {
        left: calc(-10%);
        bottom: calc(20%);
    }
    .ROLES{
        left: calc(21%);
        font-size: 62px;
        top: calc(250%);
    }
    .ROLEStext{
        width: 450px;
        margin-right: calc(2%);
        top: calc(445%);
        font-size: 19px;
        text-align: center;
    }
    .screen3{
        left: calc(3%);
        transform: rotate(-11deg);
        height: 175px;
        width: 450px;
    }
    .star9 {
        left: calc(40%);
        bottom: calc(20%);
    }
    .MANAGE{
        left: calc(-6%);
        font-size: 62px;
        top: calc(320%);
    }
    .MANAGEtext{
        width: 450px;
        left:  calc(-52%);
        top: calc(565%);
        font-size: 19px;
        text-align: center;
    }
    .screen4{
        left: calc(7%);
        top: calc(590%);
        transform: rotate(5deg);
    }
    .StatsText{
        font-size: 55px;
        margin-top: calc(-40%);
    }
    .usersnumber{
        left: calc(20%);
        top: calc(30%);
        width: 300px;
        height: 166px;
    }
    .squadsnumber{
        width: 200px;
        height: 200px;
        left: calc(32%);
        top: calc(50%);
    }
    .serversnumber{
        width: 175px;
        height: 175px;
        left: calc(33%);
        top: calc(73%);
    }
    .serverstextn{
        left: calc(30%);
        top: calc(83%);
    }
    .squadstextn{
        left: calc(35%);
        top: calc(60%);
    }
    .userstextn{
        left: calc(21%);
        top: calc(39%);
    }
    .userstextnum{
        left: calc(50%);
        top: calc(43%);
    }
    .squadstextnum{
        left: calc(50%);
        top: calc(64%);
    }
    .serverstextnum{
        left: calc(48%);
        top: calc(87%);
    }
    .background2{
        height: 1500px;
    }
}
@media (max-width: 400px){

}


@media (max-width: 415px){
    .center-logo{
        height: 480px;
        width: 480px;
        left: -80px;
    }
    .main-title{
        font-size: 85px;
        left: 30px;
        margin-top: 300px;
    }
    .sub-title{
        font-size: 20px;
        left: -5px;
        margin-top: 290px;
    }
    .action-button{
        left: -5px;
        width: 340px;
        height: 55px;
        margin-top: 290px;
    }
    .SQUADS{
        white-space: nowrap;
        left: calc(7%);
        font-size: 55px;
        top: calc(101%);
    }
    .SQUADStext{
        width: 375px;
        margin-right: calc(1%);
        top: calc(195%);
        font-size: 15px;
        text-align: center;
    }
    .screen1{
        left: calc(8%);
        top: calc(225%);
        width: 350px;
        height: 250px;
    }
    .star3 {
        right: calc(-25%);
    }
    .TEGS{
        left: calc(20%);
        font-size: 55px;
        top: calc(160%);
    }
    .TEGStext{
        width: 375px;
        top: calc(303%);
        right:  calc(5%);
        font-size: 15px;
    }
    .screen2{
        top: calc(340%);
        left: calc(14%);
        width: 300px;
        height: 330px;
    }
    .ROLES{
        left: calc(18%);
        font-size: 55px;
        top: calc(250%);
    }
    .ROLEStext{
        width: 375px;
        margin-right: calc(2%);
        top: calc(470%);
        font-size: 15px;
        text-align: center;
    }
    .screen3{
        left: calc(3%);
        top: calc(495%);
        transform: rotate(-11deg);
        height: 140px;
        width: 375px;
    }
    .star11{
        top: calc(505%);
    }
    .star9{
        top: calc(515%);
    }
    .MANAGE{
        left: calc(-5%);
        font-size: 50px;
        top: calc(300%);
    }
    .MANAGEtext{
        width: 375px;
        left:  calc(-52%);
        top: calc(560%);
        font-size: 15px;
        text-align: center;
    }
    .star12{
        top: calc(615%);
    }
    .screen4{
        left: calc(3%);
        top: calc(585%);
        transform: rotate(5deg);
        width: 375px;
        height: 300px;
    }
    .backgroundblack{
        height: 4400px;
    }
    .backgroundshadow{
        height: 4475px;
    }
    .star13{
        display: none;
    }
    .background2{
        height: 1800px;
        top: calc(650%);
    }
    .StatsText{
        font-size: 45px;
        margin-top: calc(-40%);
    }
    .usersnumber{
        left: calc(13%);
        top: calc(25%);
        width: 300px;
        height: 166px;
    }
    .squadsnumber{
        width: 200px;
        height: 200px;
        left: calc(26%);
        top: calc(45%);
    }
    .serversnumber{
        width: 175px;
        height: 175px;
        left: calc(29%);
        top: calc(68%);
    }
    .serverstextn{
        left: calc(27%);
        top: calc(78%);
    }
    .squadstextn{
        left: calc(30%);
        top: calc(55%);
    }
    .userstextn{
        left: calc(15%);
        top: calc(33%);
    }
    .userstextnum{
        left: calc(48%);
        top: calc(37%);
    }
    .squadstextnum{
        left: calc(49%);
        top: calc(59%);
    }
    .serverstextnum{
        left: calc(48%);
        top: calc(82%);
    }
}

@media (max-width: 375px){
    .center-logo{
        height: 400px;
        width: 400px;
        left: -60px;
        transform: rotate(10deg);
    }
    .main-title{
        font-size: 60px;
        left: 48px;
        margin-top: 230px;
    }
    .sub-title{
        font-size: 16px;
        left: 4px;
        margin-top: 190px;
    }
    .action-button{
        left: 15px;
        width: 250px;
        height: 55px;
        margin-top: 190px;
    }
    .SQUADS{
        white-space: nowrap;
        left: calc(7%);
        font-size: 50px;
        top: calc(101%);
    }
    .SQUADStext{
        width: 350px;
        margin-right: calc(1%);
        top: calc(182%);
        font-size: 16px;
        text-align: center;
    }
    .screen1{
        left: calc(7%);
        width: 320px;
        height: 220px;
    }
    .TEGS{
        left: calc(16%);
        font-size: 55px;
        top: calc(158%);
    }
    .TEGStext{
        width: 350px;
        right:  calc(4%);
        font-size: 16px;
        top: calc(282%);
    }
    .screen2{
        top: calc(305%);
        left: calc(15%);
        width: 275px;
        height: 300px;
    }
    .star6 {
        top: calc(350%);
    }
    .star7 {
        top: calc(370%);
    }
    .ROLES{
        left: calc(15%);
        font-size: 55px;
        top: calc(235%);
    }
    .ROLEStext{
        width: 350px;
        margin-right: calc(2%);
        top: calc(415%);
        font-size: 16px;
        text-align: center;
    }
    .screen3{
        left: calc(6%);
        top: calc(440%);
        transform: rotate(-11deg);
        height: 120px;
        width: 325px;
    }
    .star9 {
        top: calc(470%);
    }
    .star11 {
        top: calc(450%);
    }
    .MANAGE{
        left: calc(-9%);
        font-size: 50px;
        top: calc(288%);
    }
    .MANAGEtext{
        width: 350px;
        left:  calc(-52%);
        top: calc(508%);
        font-size: 16px;
        text-align: center;
    }
    .screen4{
        left: calc(9%);
        top: calc(540%);
        transform: rotate(5deg);
        width: 300px;
        height: 250px;
    }
    .star12 {
        top: calc(580%);
    }
    .backgroundblack{
        height: 4300px;
    }
    .backgroundshadow{
        height: 4375px;
    }
    .background2{
        top: calc(600%);
    }
}

@media (max-width: 320px){
    .center-logo{
        height: 400px;
        width: 400px;
        left: -85px;
        transform: rotate(10deg);
    }
    .main-title{
        font-size: 60px;
        left: 28px;
        margin-top: 230px;
    }
    .sub-title{
        font-size: 16px;
        left: -24px;
        margin-top: 190px;
    }
    .action-button{
        left: -5px;
        width: 250px;
        height: 55px;
        margin-top: 190px;
    }
    .SQUADS{
        white-space: nowrap;
        left: calc(2%);
        font-size: 45px;
        top: calc(101%);
    }
    .SQUADStext{
        width: 300px;
        margin-right: calc(1%);
        top: calc(182%);
        font-size: 14px;
        text-align: center;
    }
    .screen1{
        left: calc(6%);
        width: 300px;
        height: 200px;
    }
    .star2 {
        display: none;
    }
    .star5 {
        display: none;
    }
    .star6 {
        display: none;
    }
    .TEGS{
        left: calc(16%);
        font-size: 45px;
        top: calc(155%);
    }
    .TEGStext{
        width: 300px;
        right:  calc(4%);
        font-size: 14px;
        top: calc(275%);
    }
    .screen2{
        top: calc(305%);
        left: calc(9%);
        width: 275px;
        height: 300px;
    }
    .star7 {
        display: none;
    }
    .ROLES{
        left: calc(15%);
        font-size: 45px;
        top: calc(215%);
    }
    .ROLEStext{
        width: 300px;
        margin-right: calc(2%);
        top: calc(380%);
        font-size: 14px;
        text-align: center;
    }
    .screen3{
        left: calc(3%);
        top: calc(410%);
        transform: rotate(-11deg);
        height: 110px;
        width: 300px;
    }
    .star8 {
        display: none;
    }
    .star11 {
        top: calc(340%);
    }
    .star9 {
        display: none;
    }
    .star13 {
        display: none;
    }
    .MANAGE{
        left: calc(-8%);
        font-size: 40px;
        top: calc(265%);
    }
    .MANAGEtext{
        width: 300px;
        left:  calc(-52%);
        top: calc(468%);
        font-size: 14px;
        text-align: center;
    }
    .screen4{
        left: calc(3%);
        top: calc(500%);
        transform: rotate(5deg);
        width: 300px;
        height: 250px;
    }
    .star12 {
        top: calc(430%);
    }
    .backgroundblack{
        height: 3700px;
    }
    .backgroundshadow{
        height: 3775px;
    }
    .background2{
        top: calc(540%);
    }
    .StatsText{
        font-size: 40px;
        margin-top: calc(-80%);
    }
    .usersnumber{
        left: calc(7%);
        top: calc(20%);
        width: 280px;
        height: 144px;
    }
    .squadsnumber{
        width: 200px;
        height: 200px;
        left: calc(20%);
        top: calc(40%);
    }
    .serversnumber{
        width: 175px;
        height: 175px;
        left: calc(23%);
        top: calc(62%);
    }
    .serverstextn{
        left: calc(19%);
        top: calc(72%);
    }
    .squadstextn{
        left: calc(24%);
        top: calc(50%);
    }
    .userstextn{
        left: calc(5%);
        top: calc(27%);
    }
    .userstextnum{
        left: calc(47%);
        top: calc(31%);
    }
    .squadstextnum{
        left: calc(47%);
        top: calc(54%);
    }
    .serverstextnum{
        left: calc(47%);
        top: calc(76%);
    }
}