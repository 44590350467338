@import url('https://fonts.googleapis.com/css2?family=Anton&family=Onest:wght@100..900&family=Oxanium:wght@200..800&display=swap');

.footer {
    position: relative;
    bottom: -5500px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #F19933;
    height: 221px;
    width: 100%;
    padding: 0 20px;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.logo2 {
    height: 100px;
    width: 100px;
    position: absolute;
    bottom: calc(45%);
    user-select: none;
}

.title2 {
    font-size: 38px;
    color: white;
    font-family: 'Oxanium', sans-serif;
    font-weight: 700;
    position: absolute;
    left: calc(80px + 40px);
    cursor: default;
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
    position: absolute;
    bottom: calc(45%);
}

.Support {
    color: white;
    font-size: 24px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    bottom: calc(50%);
    left: calc(25%);
    cursor: pointer;
    user-select: none;
}

.SupportUs {
    color: white;
    font-size: 24px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    bottom: calc(50%);
    left: calc(41%);
    cursor: pointer;
    user-select: none;
}

.Terms {
    color: white;
    font-size: 24px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    bottom: calc(50%);
    left: calc(55%);
    cursor: pointer;
    user-select: none;
}

.Policy {
    color: white;
    font-size: 24px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    bottom: calc(50%);
    left: calc(72%);
    cursor: pointer;
    user-select: none;
}

.madeby {
    color: white;
    font-size: 14px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    bottom: calc(27%);
    left: calc(45%);
}

.copyright {
    color: white;
    font-size: 14px;
    font-family: 'Onest', sans-serif;
    font-weight: 500;
    position: absolute;
    bottom: calc(6%);
    left: calc(35%);
}

.Support:hover {
    text-decoration: underline;
   }

.SupportUs:hover {
    text-decoration: underline;
    }

.Terms:hover {
    text-decoration: underline;
    }

.Policy:hover {
    text-decoration: underline;
    }

@media (max-width: 1200px){
    .logo2{
        height: 80px;
        width: 80px;
    }
    .title2{
        font-size: 28px;
        left: calc(8%);
    }
    .Support {
        font-size: 20px;
        position: absolute;
        left: calc(24%);
    }
    .SupportUs{
        font-size: 20px;
        position: absolute;
        left: calc(41%);
    }
    .Terms{
        font-size: 20px;
        position: absolute;
        left: calc(56%);
    }
    .Policy{
        font-size: 20px;
        position: absolute;
        left: calc(75%);
    }
    .madeby {
        font-size: 13px;
        left: calc(42%);
    }
    
    .copyright {
        font-size: 13px;
        left: calc(26%);
    }
}
@media (max-width: 992px) {
    .logo2{
        height: 75px;
        width: 75px;
    }
    .title2{
        font-size: 26px;
        left: calc(9%);
    }
    .Support {
        font-size: 18px;
        position: absolute;
        left: calc(24%);
    }
    .SupportUs{
        font-size: 18px;
        position: absolute;
        left: calc(42%);
    }
    .Terms{
        font-size: 18px;
        position: absolute;
        left: calc(57%);
    }
    .Policy{
        font-size: 18px;
        position: absolute;
        left: calc(77%);
    }
    .madeby {
        font-size: 13px;
        left: calc(42%);
    }
    
    .copyright {
        font-size: 12px;
        left: calc(26%);
    }
}
@media (max-width: 768px) {
    .logo2{
        height: 70px;
        width: 70px;
        margin-left: calc(-2%);
        margin-bottom: calc(5%);
    }
    .title2{
        font-size: 24px;
        left: calc(11%);
        margin-left: calc(-2%);
        margin-bottom: calc(7%);
    }
    .Support {
        font-size: 16px;
        position: absolute;
        left: calc(35%);
        top: 22px;
    }
    .SupportUs{
        font-size: 16px;
        position: absolute;
        left: calc(65%);
        top: 22px;
    }
    .Terms{
        font-size: 16px;
        position: absolute;
        left: calc(35%);
        bottom: 100px;
    }
    .Policy{
        font-size: 16px;
        position: absolute;
        left: calc(63%);
        bottom: 100px;
    }
    .madeby {
        font-size: 13px;
        left: calc(40%);
    }
    
    .copyright {
        font-size: 12px;
        left: calc(26%);
    }
}
@media (max-width:550px){
    .logo2{
        height: 68px;
        width: 68px;
    }
    .title2{
        font-size: 22px;
        left: calc(15%);
    }
    .Terms{
        font-size: 15px;
        position: absolute;
        left: calc(35%);
        bottom: 100px;
    }
    .Policy{
        font-size: 15px;
        position: absolute;
        left: calc(63%);
        bottom: 100px;
    }
    .madeby {
        font-size: 13px;
        left: calc(30%);
    }
    .copyright {
        font-size: 12px;
        left: calc(2%);
    }
    .footer {
        bottom: -5650px;
    }
}
@media (max-width: 480px){
    .logo2{
        height: 65px;
        width: 65px;
        margin-left: calc(23%);
    }
    .title2{
        font-size: 22px;
        left: calc(42%);
    }
    .Terms{
        display: none;
    }
    .Policy{
        display: none;
    }
    .Support {
        display: none;
    }
    .SupportUs{
        display: none;
    }
    .madeby {
        font-size: 13px;
        left: calc(25%);
        bottom: calc(33%);
    }
    .copyright {
        font-size: 12px;
        width: 400px;
        left: calc(10%);
    }
    .footer {
        bottom: -5600px;
    }
}
@media (max-width: 415px){
    .logo2{
        height: 63px;
        width: 63px;
        margin-left: calc(20%);
    }
    .title2{
        font-size: 21px;
        left: calc(40%);
    }
    .Terms{
        display: none;
    }
    .Policy{
        display: none;
    }
    .Support {
        display: none;
    }
    .SupportUs{
        display: none;
    }
    .madeby {
        font-size: 13px;
        left: calc(19%);
    }
    .copyright {
        font-size: 12px;
        width: 300px;
        left: calc(13%);
    }
    .footer {
        bottom: -5400px;
    }
}

@media (max-width: 375px){
    .footer {
        bottom: -5250px;
    }
}


@media (max-width: 320px){
    .logo2{
        height: 60px;
        width: 60px;
        margin-left: calc(15%);
    }
    .title2{
        font-size: 20px;
        left: calc(40%);
        margin-bottom: calc(8%);
    }
    .madeby {
        font-size: 12px;
        left: calc(17%);
    }
    .copyright {
        font-size: 11px;
        width: 300px;
        left: calc(11%);
    }
    .footer {
        bottom: -4700px;
    }
}